import React, { useState, useEffect } from "react";
import { Flex, Image, Text, useDisclosure } from "@chakra-ui/react";
import s from "./ShopItem.module.scss";
import { motion } from "framer-motion";
import { ShopProductModel } from "../../ShopModel";
import { OptionsModal } from "../../../OptionsModal/OptionsModal";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { addToCart, removeFromShopItem, selectCartItems } from "../../ShopSlice";
import { useTranslation } from "react-i18next";

interface ShopItemProps {
  product: ShopProductModel;
}

export const ShopItem: React.FC<ShopItemProps> = ({ product }) => {
  const dispatch = useAppDispatch();
  const t = useTranslation("translation").t;
  const [quantity, setQuantity] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [direction, setDirection] = useState<"increment" | "decrement">("increment");
  const [, setSelectedOptions] = useState<{ [key: string]: string | string[] }>({});
  const cart = useAppSelector(selectCartItems);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (quantity > 0) {
      setAnimate(true);
      const timer = setTimeout(() => setAnimate(false), 300);
      return () => clearTimeout(timer);
    }
  }, [quantity]);

  useEffect(() => {
    cart?.forEach(item => {
      if (item.product.id === product.id) {
        setQuantity(prev => prev + item.quantity);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddToCart = (options: { [key: string]: string | string[] }, qty: number) => {
    setDirection("increment");
    setQuantity(prev => prev + qty);
    setSelectedOptions(options);
    dispatch(addToCart({ product, quantity: qty, selectedOptions: options }));
    onClose();
  };

  const handleRemoveFromCart = () => {
    if (quantity > 0) {
      setDirection("decrement");
      setQuantity(prev => prev - 1);
      dispatch(removeFromShopItem({ productId: product.id }));
    }
  };

  const animation = direction === "increment" ? { scale: [1, 1.2, 1] } : { scale: [1, 0.8, 1] };

  return (
    <Flex className={s.shopItem}>
      <Flex pos="relative">
        <Image className={s.image} src={product.imageUrl} alt={product.name} onClick={onOpen} borderRadius="lg" />
        {quantity > 0 && (
          <motion.div className={s.counterBadge} animate={animate ? animation : {}} transition={{ duration: 0.3 }}>
            <Text>{quantity}</Text>
          </motion.div>
        )}
      </Flex>
      <Flex className={s.nameBlock}>
        <Text className={s.name} isTruncated>
          {product.name}
        </Text>
        <Text className={s.price} fontWeight="bold">
          {t("$")}
          {product.price}
        </Text>
      </Flex>
      <Flex pos={"relative"} w="85%">
        <motion.div
          className={s.addButton}
          onClick={onOpen}
          animate={{ width: quantity > 0 ? "50%" : "100%" }}
          transition={{ duration: 0.2 }}
        >
          {quantity === 0 ? `${t("Add")}` : "+"}
        </motion.div>
        <motion.div className={s.minusButton} onClick={handleRemoveFromCart}>
          -
        </motion.div>
      </Flex>
      <OptionsModal product={product} isOpen={isOpen} onClose={onClose} addToCart={handleAddToCart} />
    </Flex>
  );
};
