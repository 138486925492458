import { useCallback, useEffect } from "react";
import s from "./ShoppingCart.module.scss";
import { Flex, Text, Image } from "@chakra-ui/react";
import { useTelegram } from "../../common/hooks/useTelegram";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CartItem } from "../Shop/ShopState";
import { addToCart, removeFromCart, selectCartItems, ShopSlice } from "../Shop/ShopSlice";
import { useTranslation } from "react-i18next";
import { ShopProductModel } from "../Shop/ShopModel";

export const ShoppingCartContainer = () => {
  const t = useTranslation("translation").t;
  const { tg } = useTelegram();
  const nav = useNavigate();
  const { orgId, lng } = useParams();
  const dispatch = useDispatch();

  const cartItems = useSelector(selectCartItems);

  const onSendData = useCallback(() => {
    dispatch(ShopSlice.actions.createOrder());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems, tg]);

  useEffect(() => {
    tg.MainButton.setText(t("SEND THE ORDER"));
    tg.BackButton.show();
    tg.BackButton.onClick(() => {
      navigate();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems, orgId, tg, lng]);

  useEffect(() => {
    tg.onEvent("mainButtonClicked", onSendData);
    return () => {
      tg.offEvent("mainButtonClicked", onSendData);
    };
  }, [onSendData, tg]);

  const navigate = () => {
    nav(`/shop`);
  };

  const handleAddQuantity = (item: CartItem) => {
    dispatch(addToCart({ product: item.product, quantity: 1, selectedOptions: item.selectedOptions }));
  };

  const handleRemoveQuantity = (item: CartItem) => {
    dispatch(removeFromCart({ productId: item.product.id, selectedOptions: item.selectedOptions }));
  };

  const calculateTotalPrice = (
    product: ShopProductModel,
    quantity: number,
    selectedOptions: { [key: string]: string | string[] },
  ) => {
    let totalPrice = product.price * quantity;

    product.options.forEach(option => {
      const selectedValue = selectedOptions[option.name];

      if (selectedValue) {
        option.values.forEach(value => {
          if (Array.isArray(selectedValue)) {
            if (selectedValue.includes(value.name)) {
              totalPrice += value.adPrice * quantity;
            }
          } else {
            if (value.name === selectedValue) {
              totalPrice += value.adPrice * quantity;
            }
          }
        });
      }
    });

    return totalPrice;
  };

  if (!cartItems) return null;

  if (cartItems.length === 0) {
    navigate();
  }

  return (
    <Flex className={s.container}>
      <Flex className={s.header}>
        <Text className={s.title}>{t("YOUR ORDER")}</Text>
        <Text className={s.edit} onClick={navigate}>
          {t("Edit")}
        </Text>
      </Flex>
      <Flex className={s.cartItems}>
        {cartItems.map(({ product, quantity, selectedOptions }) => (
          <Flex key={product.id} className={s.item}>
            <Flex className={s.info}>
              <Image className={s.image} src={product.imageUrl} alt={product.name} />
              <Flex className={s.nameAndOptions}>
                <Flex className={s.name}>
                  <Text className={s.nameText}>{product.name}</Text>
                </Flex>
                {Object.entries(selectedOptions).map(([key, value], index) => (
                  <Text className={s.options} key={index}>
                    {key}: {Array.isArray(value) ? value.join(", ") : value}
                    <br />
                  </Text>
                ))}
              </Flex>
            </Flex>
            <Flex className={s.priseAndButtons}>
              <Text className={s.price}>
                {t("$")}
                {calculateTotalPrice(product, quantity, selectedOptions)}
              </Text>
              <Flex justify="space-between" align="center" gap="4px">
                <Flex className={s.minusButton} onClick={() => handleRemoveQuantity({ product, quantity, selectedOptions })}>
                  -
                </Flex>
                <Text>{quantity}</Text>
                <Flex className={s.addButton} onClick={() => handleAddQuantity({ product, quantity, selectedOptions })}>
                  +
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
