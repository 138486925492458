import { CartItem } from "./ShopState";
import { CartItemData, CartItemOptionData } from "./ShopData";

export const mapCartModelsToData = (model: CartItem[]): CartItemData[] => {
  return model.map(item => {
    const optionKeys = Object.keys(item.selectedOptions);

    const options: CartItemOptionData[] = optionKeys.map(optionKey => {
      const value = item.selectedOptions[optionKey];
      let values: string[] = [];
      values = values.concat(value);
      return { name: optionKey, values: values };
    });

    return {
      ...item,
      selectedOptions: options,
    };
  });
};
