import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import { ShopState } from "./ShopState";
import { ShopProductModel } from "./ShopModel";

const initialState: ShopState = {
  shopProductList: [],
  cart: [],
};

export const ShopSlice = createSlice({
  name: "shopSlice",
  initialState,
  reducers: {
    getShopProductList: state => {
      return {
        ...state,
      };
    },
    getShopProductListCompleted: (state, action: PayloadAction<ShopProductModel[]>) => {
      return {
        ...state,
        shopProductList: action.payload,
      };
    },
    getShopProductListDraft: state => {
      return {
        ...state,
      };
    },
    getShopProductListDraftCompleted: (state, action: PayloadAction<ShopProductModel[]>) => {
      return {
        ...state,
        shopProductList: action.payload,
      };
    },
    addToCart: (
      state,
      action: PayloadAction<{
        product: ShopProductModel;
        quantity: number;
        selectedOptions: { [key: string]: string | string[] };
      }>,
    ) => {
      const { product, quantity, selectedOptions } = action.payload;
      const existingItem = state.cart?.find(
        item => item.product.id === product.id && JSON.stringify(item.selectedOptions) === JSON.stringify(selectedOptions),
      );

      if (existingItem) {
        existingItem.quantity += quantity;
      } else {
        state.cart?.unshift({ product, quantity, selectedOptions });
      }
    },
    removeFromCart: (
      state,
      action: PayloadAction<{
        productId: string;
        selectedOptions: { [key: string]: string | string[] };
      }>,
    ) => {
      const { productId, selectedOptions } = action.payload;
      const existingItem = state.cart?.find(
        item => item.product.id === productId && JSON.stringify(item.selectedOptions) === JSON.stringify(selectedOptions),
      );

      if (existingItem) {
        if (existingItem.quantity > 1) {
          existingItem.quantity -= 1;
        } else {
          state.cart = state.cart?.filter(item => item !== existingItem);
        }
      }
    },
    removeFromShopItem: (
      state,
      action: PayloadAction<{
        productId: string;
      }>,
    ) => {
      const { productId } = action.payload;
      const existingItem = state.cart?.find(item => item.product.id === productId);

      if (existingItem) {
        if (existingItem.quantity > 1) {
          existingItem.quantity -= 1;
        } else {
          state.cart = state.cart?.filter(item => item !== existingItem);
        }
      }
    },
    createOrder: state => {
      return {
        ...state,
      };
    },
  },
});

export const { getShopProductList, getShopProductListDraft, addToCart, removeFromCart, removeFromShopItem } = ShopSlice.actions;

export const selectShopProductList = (state: RootState) => state.shopState.shopProductList;
export const selectCartItems = (state: RootState) => state.shopState.cart;

export default ShopSlice.reducer;
